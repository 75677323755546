@import (reference) '../../style/global.less';

.feature {
    & > :global(.anticon) {
        color: @alude-red-orange;
        &:extend(.large-alude);
    }
}

.drawer {
    padding: 16px;
    & > *:not(:last-child) {
        padding-bottom: 16px;
    }
}
